import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { HERO_IMG_URL } from '@/components/LandingMozza/common/Hero/constants';
import Reason from '@/components/LandingMozza/common/Hero/Reason/Reason';
import SearchBar from '@/components/LandingMozza/common/Hero/SearchBar/SearchBar';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import useIsClient from '@/hooks/useIsClient';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  reasons?: string[] | ReactElement[];
}
const Hero: FC<Props> = ({ title, subtitle, reasons }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const isClient = useIsClient();
  const { featureFlag: homeToSearchFeatureFlag } =
    useFeatureFlag('home_to_search');

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.header}>
          {title ? (
            <Typography component="h1" variant="title2Xl">
              {title}
            </Typography>
          ) : (
            <Typography component="span" variant="title2Xl">
              {title ?? <FormattedMessage {...messages.title} />}
            </Typography>
          )}
          <div>
            {subtitle ? (
              <Typography
                component="h2"
                variant="bodyXl"
                className={classes.neutralText}
              >
                {subtitle}
              </Typography>
            ) : (
              <Typography
                component="h1"
                variant="bodyXl"
                className={classes.neutralText}
              >
                <FormattedMessage {...messages.subtitlePart1} />
                <FormattedMessage {...messages.subtitlePart2} />
              </Typography>
            )}
          </div>
        </div>
        {reasons?.length > 0 && (
          <ul className={classes.reasons}>
            {reasons.map((reason, index) => (
              <Reason key={`reason-${index}`}>
                <Typography component="span" className={classes.neutralText}>
                  {reason}
                </Typography>
              </Reason>
            ))}
          </ul>
        )}

        <SearchBar />
      </div>

      {isClient && homeToSearchFeatureFlag === 'test' ? (
        <div />
      ) : (
        <div className={classes.imageContainer}>
          <Image
            priority
            fetchPriority="high"
            loading="eager"
            src={HERO_IMG_URL}
            width={512}
            height={496}
            loader={() =>
              getImageFromCloudImage({
                quality: 100,
                src: HERO_IMG_URL,
                width: 512,
              })
            }
            quality={100}
            alt={formatMessage(messages.imageAlt)}
          />
        </div>
      )}
    </div>
  );
};

export default Hero;
