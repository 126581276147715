import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.LandingMozza.Hero.title',
    defaultMessage:
      "Avec Les Sherpas, c'est si facile de progresser qu'on a l'impression de tricher !",
  },
  subtitlePart1: {
    id: 'app.components.LandingMozza.Hero.subtitlePart1',
    defaultMessage: 'Notre mission : ',
  },
  subtitlePart2: {
    id: 'app.components.LandingMozza.Hero.subtitlePart2',
    defaultMessage: 'des cours particuliers accessibles à tous.',
  },
  imageAlt: {
    id: 'app.components.LandingMozza.Hero.altImage',
    defaultMessage: 'Profs de maths et d’anglais certifiés sur Sherpas.com',
  },
});
